import { Pricing, Store } from '@services/pricing'
import { Statsig } from 'statsig-react'
import { trackAmplitudeEvent } from './amplitude'
import { getFBPCookies, sendFacebookEvent } from './tracking'
import { moengage } from './moengage'
import { isDev } from '@utils/checkEnv'

// https://developers.facebook.com/docs/facebook-pixel/advanced/
const trackFBEvent = (name: string, options: unknown = {}, eventData: unknown = {}): void => {
    if (!isDev()) window.fbq('track', name, options, eventData)
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
const trackGTAGEvent = (name: string, options: unknown = {}): void => {
    if (!isDev()) window.gtag('event', name, options)
}

const trackPinEvent = (name: string, options: unknown = {}): void => {
    //if (!isDev()) window.pintrk('track', name, options)
}

const trackTikTokEvent = (name: string, options: unknown = {}): void => {
    if (!isDev()) window.ttq.track(name, options)
}

const trackTwitterEvent = (event: string, name: string, options: unknown = {}): void => {
    if (!isDev()) window.twq(event, name, options)
}

const trackKayzenEvent = (name: string, canClaim: boolean, options: Record<string, any> = {}): void => {
    if (!isDev()) {
        window.ktag(name, canClaim, options)
    }
}

// https://businesshelp.snapchat.com/s/article/pixel-website-install?language=en_US&_ga=2.267074318.1495059954.1616752033-521277455.1616752033
// const trackSnapEvent = (name: string, options: unknown = {}): void => {
//     window.snaptr('track', name, options)
// }

export const trackAmplitudeAndGTAGEvent = (name: string, options?: { [key: string]: unknown }): void => {
    trackGTAGEvent(name, options)
    trackAmplitudeEvent(name, options)
}

export const trackScreenView = (name: string, properties?: { [key: string]: unknown }): void => {
    trackAmplitudeAndGTAGEvent('screen_view', {
        screen_name: name,
        ...properties,
    })
    trackFBEvent('ViewContent', { content_name: name })
    Statsig.logEvent('screen_view', name)
}

export const trackPageView = (): void => {
    if (!isDev()) {
        trackFBEvent('PageView')
        trackPinEvent('pagevisit')
        window.ttq.page()
        // trackSnapEvent('PAGE_VIEW')
    }
}

export const trackEvent = (name: string, options: unknown = {}): void => {
    trackFBEvent(name, options)
    trackGTAGEvent(name, options)
    trackPinEvent(name, options)
}

export const trackLead = (params: { uid: string; email: string }): void => {
    trackFBEvent('Lead', {}, { eventID: `Lead_${params.uid}` })
    trackGTAGEvent('generate_lead')
    moengage.trackEvent('lead')
    trackTwitterEvent('event', 'tw-o5oz0-oe4w4', {
        conversion_id: `Lead_${params.uid}`, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
        email_address: params.email, // use this to pass a user’s email address
    })
    trackPinEvent('lead')
    // trackSnapEvent('SAVE')
    // window.ktag('add_email', true)
    // window.hj('event', 'lead_created')
}

export const trackPricingTableSeen = (
    price: Pricing,
    pricing_variant: string,
    stripeAccount: string,
    checkout_variant: string,
): void => {
    trackAmplitudeAndGTAGEvent('pricing_table_seen', {
        pricing_variant,
        currency: price.currency,
        stripe_account: stripeAccount,
        checkout_variant: checkout_variant,
    })
    trackGTAGEvent('view_item_list', {
        item_list_id: pricing_variant,
    })
    moengage.trackEvent('paywall_opened', { pricing_variant, currency: price.currency })
    Statsig.logEvent('pricing_table_seen', pricing_variant, {
        currency: price.currency,
    })
    trackKayzenEvent('pricing_table_seen', true, {
        pricing_variant,
        currency: price.currency,
        stripe_account: stripeAccount,
        checkout_variant: checkout_variant,
    })
}

export const trackPriceSelected = (price: Pricing, pricing_variant: string): void => {
    trackAmplitudeAndGTAGEvent('package_selected', { ...price, pricing_variant })
    trackGTAGEvent('select_item', {
        item_list_id: pricing_variant,
        items: [
            {
                item_id: price.id,
                item_name: price.id,
                discount: price.saving,
                coupon: price.stripe_coupon_id,
                price: price.price,
                quantity: 1,
            },
        ],
    })
}

export const trackCheckoutOpen = (price: Pricing, pricing_variant: string): void => {
    trackAmplitudeAndGTAGEvent('checkout_opened', { ...price, pricing_variant })
    trackGTAGEvent('view_cart', {
        value: price.intro_price || price.price, // use this to pass the value of the conversion (e.g. 5.00)
        currency: price.currency.toUpperCase(),
        items: [
            {
                item_id: price.id,
                item_name: price.id,
                discount: price.saving,
                coupon: price.stripe_coupon_id,
                price: price.price,
                quantity: 1,
            },
        ],
    })
    moengage.trackEvent('paywall_checkout_opened', { ...price, pricing_variant })
    Statsig.logEvent('checkout_opened', price.intro_price ?? price.price, {
        plan_id: price.id,
        currency: price.currency,
        pricing_variant,
    })
    trackKayzenEvent('checkout_opened', true, {
        ...price,
        pricing_variant,
    })
}

export const trackCheckoutClose = (price: Pricing, pricing_variant: string): void => {
    trackAmplitudeAndGTAGEvent('checkout_closed', { ...price, pricing_variant })
    Statsig.logEvent('checkout_closed', price.intro_price ?? price.price, {
        plan_id: price.id,
        currency: price.currency,
        pricing_variant,
    })
}

export const trackCheckoutMethodSelected = (provider: Store, price: Pricing): void => {
    trackAmplitudeAndGTAGEvent('checkout_method_selected', { provider, ...price })
    Statsig.logEvent('checkout_method_selected', price.intro_price ?? price.price, {
        plan_id: price.id,
        provider,
        currency: price.currency,
    })
}

export const trackInitiateCheckout = (
    uid: string,
    provider: Store,
    price: Pricing,
    pricing_variant: string,
    checkout_variant: string,
): void => {
    trackAmplitudeAndGTAGEvent('payment_started', {
        provider,
        pricing_variant,
        ...price,
        checkout_variant,
    })
    moengage.trackEvent('purchase_started', { provider, pricing_variant, ...price })
    trackFBEvent(
        'InitiateCheckout',
        { value: price.intro_price || price.price, currency: price.currency.toUpperCase() },
        { eventID: `InitiateCheckout_${uid}` },
    )
    trackGTAGEvent('begin_checkout', {
        value: price.intro_price || price.price, // use this to pass the value of the conversion (e.g. 5.00)
        currency: price.currency.toUpperCase(),
        coupon: price.stripe_coupon_id,
        items: [
            {
                item_id: price.id,
                item_name: price.id,
                discount: price.saving,
                coupon: price.stripe_coupon_id,
                price: price.price,
                quantity: 1,
            },
        ],
    })
    trackTwitterEvent('event', 'tw-o5oz0-oe4vr', {
        value: price.intro_price || price.price, // use this to pass the value of the conversion (e.g. 5.00)
        currency: price.currency.toUpperCase(), // use this to pass the currency of the conversion with an ISO 4217 code (e.g. ‘USD’)
        conversion_id: `InitiateCheckout_${uid}`, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
    })
    Statsig.logEvent('payment_started', price.intro_price ?? price.price, {
        plan_id: price.id,
        provider,
        currency: price.currency,
        pricing_variant,
    })
    trackTikTokEvent('InitiateCheckout', {
        content_type: 'product',
        value: price.intro_price || price.price,
        currency: price.currency.toUpperCase(),
    })

    // trackSnapEvent('START_CHECKOUT')
    // window.ktag('checkout', true)
    // window.hj('event', 'checkout_opened')

    if (!isDev()) {
        const fbCookies = getFBPCookies()
        sendFacebookEvent({
            event: 'InitiateCheckout',
            userID: uid,
            _fbc: fbCookies._fbc,
            _fbp: fbCookies._fbp,
            price: price.intro_price || price.price,
            currency: price.currency.toUpperCase(),
        })
    }
}

export const trackCheckoutCanceled = (
    uid: string,
    provider: Store,
    price: Pricing,
    pricing_variant: string,
    checkout_variant: string,
): void => {
    trackAmplitudeAndGTAGEvent('payment_canceled', {
        provider,
        pricing_variant,
        ...price,
        checkout_variant,
    })
    moengage.trackEvent('purchase_failed', { provider, pricing_variant, ...price, error: 'canceled' })
    Statsig.logEvent('payment_canceled', price.intro_price ?? price.price, {
        plan_id: price.id,
        provider,
        currency: price.currency,
        pricing_variant,
    })
}

export const trackCheckoutError = (
    uid: string,
    provider: Store,
    price: Pricing,
    pricing_variant: string,
    error: any,
    checkout_variant: string,
): void => {
    trackAmplitudeAndGTAGEvent('payment_failed', {
        provider,
        pricing_variant,
        ...price,
        error,
        checkout_variant,
    })
    moengage.trackEvent('purchase_failed', { provider, pricing_variant, ...price, error })
    Statsig.logEvent('payment_failed', price.intro_price ?? price.price, {
        plan_id: price.id,
        provider,
        pricing_variant,
        currency: price.currency,
    })
}

export const trackCompleteRegistration = (params: {
    uid: string
    email: string
    payment?: { provider: Store; price: Pricing; pricing_variant: string }
}): void => {
    trackAmplitudeEvent('registration_completed')
    moengage.trackEvent('registration_completed')
    trackFBEvent('CompleteRegistration', {}, { eventID: `CompleteRegistration_${params.uid}` })
    trackGTAGEvent(
        'sign_up',
        params.payment
            ? {
                  value: params.payment.price.intro_price || params.payment.price.price, // use this to pass the value of the conversion (e.g. 5.00)
                  currency: params.payment.price.currency.toUpperCase(),
                  coupon: params.payment.price.stripe_coupon_id,
                  items: [
                      {
                          item_id: params.payment.price.id,
                          item_name: params.payment.price.id,
                          discount: params.payment.price.saving,
                          price: params.payment.price.price,
                          quantity: 1,
                      },
                  ],
              }
            : null,
    )
    trackTwitterEvent('event', 'tw-o5oz0-oe4w4', {
        conversion_id: `Registration_${params.uid}`, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
        email_address: params.email, // use this to pass a user’s email address
    })
    Statsig.logEvent('registration_completed')
    trackPinEvent('Signup')
    trackTikTokEvent('CompleteRegistration')
    // trackSnapEvent('SIGN_UP')
    // window.hj('event', 'registration_completed')
}

export const trackPurchase = ({
    uid,
    provider,
    price,
    pricing_variant,
    checkout_variant,
    clv_usd,
}: {
    uid: string
    provider: Store
    price: Pricing
    pricing_variant: string
    checkout_variant: string
    clv_usd: number
}): void => {
    trackAmplitudeAndGTAGEvent('purchase_succeeded', { provider, pricing_variant, ...price, checkout_variant, clv_usd })
    moengage.trackEvent('purchase_succeeded', { provider, pricing_variant, ...price, clv_usd })
    trackFBEvent(
        'Purchase',
        { value: price.intro_price || price.price, currency: price.currency.toUpperCase() },
        { eventID: `Purchase_${uid}` },
    )

    trackGTAGEvent('purchase', {
        value: price.intro_price || price.price, // use this to pass the value of the conversion (e.g. 5.00)
        currency: price.currency.toUpperCase(),
        coupon: price.stripe_coupon_id,
        items: [
            {
                item_id: price.id,
                item_name: price.id,
                discount: price.saving,
                price: price.price,
                quantity: 1,
            },
        ],
    })
    Statsig.logEvent('purchase_succeeded', price.intro_price_usd ?? price.price_usd, {
        plan_id: price.id,
        provider,
        currency: price.currency,
        pricing_variant,
    })
    Statsig.logEvent('purchase_clv', clv_usd, {
        plan_id: price.id,
        provider,
        currency: price.currency,
        pricing_variant,
    })

    trackTwitterEvent('event', 'tw-o5oz0-oe4wb', {
        value: price.intro_price || price.price, // use this to pass the value of the conversion (e.g. 5.00)
        currency: price.currency.toUpperCase(), // use this to pass the currency of the conversion with an ISO 4217 code (e.g. ‘USD’)
        conversion_id: `Purchase_${uid}`, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
    })
    // trackGTAGEvent('conversion', { value: price, currency, send_to: 'AW-747219202/Qr4CCMiokfwBEILSpuQC' })
    trackPinEvent('checkout', { value: price.intro_price ?? price.price, currency: price.currency, order_quantity: 1 })
    // trackSnapEvent('START_TRIAL', { price, currency })
    // window.ktag('purchase', true, price + trial_price)
    // window.hj('event', 'purchase_succeeded')

    trackTikTokEvent('CompletePayment', {
        content_type: 'product',
        value: price.intro_price || price.price,
        currency: price.currency.toUpperCase(),
    })
    trackKayzenEvent('purchase_succeeded', true, {
        revenue: price.intro_price || price.price,
        currency: price.currency,
        provider,
        pricing_variant,
        checkout_variant,
        clv_usd,
    })

    if (!isDev()) {
        const fbCookies = getFBPCookies()
        sendFacebookEvent({
            event: 'Purchase',
            userID: uid,
            _fbc: fbCookies._fbc,
            _fbp: fbCookies._fbp,
            price: price.intro_price || price.price,
            currency: price.currency.toUpperCase(),
        })
    }
}

export const trackDownload = (params: { uid: string; email: string }): void => {
    window.fbq('trackCustom', 'ClickToDownload', {}, { eventID: `ClickToDownload_${params.uid}` })
    trackAmplitudeEvent('download_clicked')
    trackGTAGEvent('click_to_download')
    trackTwitterEvent('event', 'tw-o5oz0-oe4vm', {
        conversion_id: `Download_${params.uid}`, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
        email_address: params.email,
    })
    Statsig.logEvent('download_clicked')
    trackTikTokEvent('Download')
    // trackSnapEvent('OPEN_APP')
    // window.hj('event', 'download_clicked')
}

// export const trackYoutubeConversionEvent = ({
//     price,
//     currency,
//     orderId,
// }: {
//     price: number
//     currency: string
//     orderId: string
// }) => {
//     trackGTAGEvent('conversion', {
//         send_to: 'AW-10797815083/0TNTCOKM04MDEKuq5pwo',
//         value: price,
//         currency: currency,
//         transaction_id: orderId,
//     })
// }

//Insurance Funnel form Paywall
//Tracks initated of insurance funnel comming from the paywall
export const trackInsuranceStarted = (uid: string): void => {
    trackAmplitudeAndGTAGEvent('insurance_started', {
        uid,
    })
    Statsig.logEvent('insurance_started', null, {
        uid,
    })
}

//Events on Insurance Paywall
export const trackInsurancePaywallView = (): void => {
    trackAmplitudeAndGTAGEvent('insurance_paywall_view')
    Statsig.logEvent('insurance_paywall_view')
}

export const trackInsurancePaywallCTAClick = (): void => {
    trackAmplitudeAndGTAGEvent('insurance_paywall_cta_click')
    Statsig.logEvent('insurance_paywall_cta_click')
}

export const trackInsuranceModalClose = (): void => {
    trackAmplitudeAndGTAGEvent('insurance_modal_close')
    Statsig.logEvent('insurance_modal_close')
}

export const trackInsuranceSelected = (insuranceProvider: string): void => {
    trackAmplitudeAndGTAGEvent('insurance_selected', {
        provider: insuranceProvider,
    })
    Statsig.logEvent('insurance_selected', null, {
        provider: insuranceProvider,
    })
}

export const trackInsuranceSearchStarted = (searchTerm: string): void => {
    trackAmplitudeAndGTAGEvent('insurance_search_started', {
        search_term: searchTerm,
    })
    Statsig.logEvent('insurance_search_started', null, {
        search_term: searchTerm,
    })
}

export const trackInsuranceSelectionCompleted = (insuranceProvider: string): void => {
    trackAmplitudeAndGTAGEvent('insurance_selection_completed', {
        provider: insuranceProvider,
    })
    Statsig.logEvent('insurance_selection_completed', null, {
        provider: insuranceProvider,
    })
}

//Insurance Checkout for Invoice Page
export const trackInitiateInsuranceCheckout = (
    uid: string,
    insuranceProvider: string,
    courseId: string,
    price: number,
): void => {
    trackAmplitudeAndGTAGEvent('insurance_checkout_started', {
        provider: insuranceProvider,
        course_id: courseId,
        price: price.toString(),
    })

    trackFBEvent(
        'InitiateCheckout',
        { value: price.toString(), currency: 'EUR' },
        { eventID: `InitiateCheckout_${uid}` },
    )

    Statsig.logEvent('insurance_checkout_started', null, {
        provider: insuranceProvider,
        course_id: courseId,
    })

    if (!isDev()) {
        const fbCookies = getFBPCookies()
        sendFacebookEvent({
            event: 'InitiateCheckout',
            userID: uid,
            _fbc: fbCookies._fbc,
            _fbp: fbCookies._fbp,
            price: price,
            currency: 'EUR',
        })
    }

    trackTwitterEvent('event', 'tw-o5oz0-oe4vr', {
        value: price,
        currency: 'EUR',
        conversion_id: `InitiateCheckout_${uid}`,
    })

    trackTikTokEvent('InitiateCheckout', {
        content_type: 'insurance',
        value: price,
        currency: 'EUR',
    })
}

export const trackCompleteInsuranceCheckout = (params: {
    uid: string
    email: string
    insuranceProvider: string
    price: number
}): void => {
    trackAmplitudeAndGTAGEvent('insurance_checkout_completed', {
        provider: params.insuranceProvider,
        price: params.price.toString(),
    })

    trackFBEvent('Purchase', { value: params.price.toString(), currency: 'EUR' }, { eventID: `Purchase_${params.uid}` })

    Statsig.logEvent('insurance_checkout_completed', null, {
        provider: params.insuranceProvider,
        price: params.price.toString(),
    })

    if (!isDev()) {
        const fbCookies = getFBPCookies()
        sendFacebookEvent({
            event: 'Purchase',
            userID: params.uid,
            _fbc: fbCookies._fbc,
            _fbp: fbCookies._fbp,
            price: params.price,
            currency: 'EUR',
        })
    }

    trackTwitterEvent('event', 'tw-o5oz0-oe4wb', {
        value: params.price,
        currency: 'EUR',
        conversion_id: `Purchase_${params.uid}`,
    })

    trackTikTokEvent('CompletePayment', {
        content_type: 'insurance',
        value: params.price,
        currency: 'EUR',
    })

    trackKayzenEvent('insurance_purchase_succeeded', true, {
        revenue: params.price,
        currency: 'EUR',
        provider: params.insuranceProvider,
    })
}

export const trackInsuranceCheckoutError = (params: {
    uid: string
    insuranceProvider: string
    error: string
}): void => {
    trackAmplitudeAndGTAGEvent('insurance_checkout_failed', {
        provider: params.insuranceProvider,
        error: params.error,
    })

    Statsig.logEvent('insurance_checkout_failed', null, {
        provider: params.insuranceProvider,
        error: params.error,
    })
}
